/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  z-index: 10;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(107, 114, 128, 0.8);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #068983;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #068983;
}
.MuiDialog-paper {
  max-width: 80% !important;
}
